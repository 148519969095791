import React from 'react';
import styles from './styles.module.scss'
import {ReactComponent as InstagramIcon} from "./instagram.svg";

const About = () => {

    const email = 'lisakuntysh@gmail.com';
    const familia = 'https://www.byfamilia.com/'
    const tokyo = 'https://www.soonintokyo.com/'

    return (<div className={styles.about}>
        <div className={styles.text}>
            my name is Lisa Kuntysh and i am a freelance designer from russia. previously worked at <a target={'_blank'} href={familia}>familia</a>, currently at <a target={'_blank'} href={tokyo}>soon in tokyo</a> in barcelona, spain. <br/> <br/>

            i am interested in creating a strong coherent idea behind brand’s visuals that is why i am specialising
            in graphic solutions for businesses and individuals. i also run a small print center in sochi,
            russia and fond of different print techniques.<br/> <br/>

            i hope one day i will get the feeling that I help this world to become a better place.
            currently available for freelance projects. if you would like to reach out to discuss a potential project
            or collaboration ideas please write to <a target={'_blank'} href={'mailto:'+email}>{email}</a>

            <br/>
            <br/>

            <a className={styles.instagram} href="https://www.instagram.com/lisa.kuntysh" target={'_blank'}><InstagramIcon width={30} height={30} /> @lisa.kuntysh</a>
        </div>
    </div>)
}

export default About;
