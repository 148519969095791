export const types = {
  SET_DATA: 'APP/SET_DATA',
};

const initialState = {
  portfolio: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const actions = {
  setData: (data) => async (dispatch) => {

    await dispatch({
      type: types.SET_DATA,
      payload: data,
    });
  },
};
