import axios from 'axios';
const host = 'https://api.lisakuntysh.com/';
const Api = (url = '', params = {}, method = 'get', headers = {}) => {

  return axios({
    url,
    method,
    headers,
    params: method === 'get' ? params : null,
    data: method !== 'get' ? params : null,

    baseURL: host,
  });
};

export {host}

export default Api;
