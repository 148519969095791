import React, {useEffect} from 'react';
import Playcanvas from './components/playcanvas/Playcanvas';
import {BrowserRouter as Router} from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AppRouter from './components/router/Router';
import {useDispatch} from 'react-redux';
import Api from './services/api';
import {actions} from './store/app';

const App = () => {
  const dispatch = useDispatch();

  const init = async () => {
    const {data: portfolio} = await Api('projects/index');
    dispatch(actions.setData({portfolio}));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Router>
      <Playcanvas />
      <Header />
      <AppRouter />
      <Footer />
    </Router>
  );
};

export default App;
