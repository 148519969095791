import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import './trix.scss'
import {useParams} from 'react-router-dom';
import Api from '../../services/api';

const Case = () => {
  const {id} = useParams();
  const [content, setContent] = useState(false);

  const loadCase = async (id) => {
    const {data} = await Api('projects/view', {id});
    setContent(data.content);
  };

  useEffect(() => {
    loadCase(id);
  }, [id]);

  if (!content) return null;

  return (
    <div className={styles.content}>
      <div className={'trix-content'} dangerouslySetInnerHTML={{__html: content}} />
    </div>
  );
};

export default Case;
