import React, {useEffect, useRef, useCallback} from 'react';
import * as pc from 'playcanvas/build/playcanvas.min';
import generateRefractionScript from './refraction';
import styles from './styles.module.scss';
import {useLocation} from 'react-router-dom';
import classnames from 'classnames/bind';

const rayDepth = 1;

const cx = classnames.bind(styles);

const Playcanvas = () => {
  const location = useLocation();
  const canvasRef = useRef(null);
  const appRef = useRef(null);
  const mousePositionRef = useRef(new pc.Vec3());
  const mouseTestBoundingBoxRef = useRef(new pc.BoundingBox());
  const starDefaultPositionRef = useRef(new pc.Vec3(0.228, 0, -0.112));
  const starTargetPositionRef = useRef(new pc.Vec3());
  const textTargetEulerRef = useRef(new pc.Vec3(0, 0, 0));

  const onMouseMove = function (event) {
    const camera = pc.app.root.findByName('camera');
    camera.camera.screenToWorld(event.x, event.y, rayDepth, mousePositionRef.current);

    const isCursorInBoundingBox = mouseTestBoundingBoxRef.current.containsPoint(mousePositionRef.current);

    //if (isCursorInBoundingBox) {
      starTargetPositionRef.current = mousePositionRef.current;
      textTargetEulerRef.current = new pc.Vec3();
      textTargetEulerRef.current.x = starTargetPositionRef.current.z * 80;
      textTargetEulerRef.current.z = -starTargetPositionRef.current.x * 80;
    /*} else {
      starTargetPositionRef.current = starDefaultPositionRef.current;
      textTargetEulerRef.current.x = 0;
      textTargetEulerRef.current.z = 0;
    }*/
  };

  const createStar = async () => {
    const modelAsset = await new Promise((resolve, reject) => {
      pc.app.assets.loadFromUrl('/models/star/star.json', 'model', (error, asset) => {
        error ? reject(error) : resolve(asset);
      });
    });

    const cubemap = await new Promise((resolve, reject) => {
      pc.app.assets.loadFromUrl('/models/cubemap/41428714/cubemap.dds', 'cubemap', (error, asset) => {
        error ? reject(error) : resolve(asset);
      });
    });

    const star = new pc.Entity('star');
    star.addComponent('model', {
      asset: modelAsset,
    });

    star.model.material._cubeMap = cubemap.resource;
    star.model.meshInstances[0].material.prefilteredCubeMap128 = cubemap.resources[1];
    star.model.meshInstances[0].material._prefilteredCubeMap64 = cubemap.resources[2];
    star.model.meshInstances[0].material._prefilteredCubeMap32 = cubemap.resources[3];
    star.model.meshInstances[0].material._prefilteredCubeMap16 = cubemap.resources[4];
    star.model.meshInstances[0].material._prefilteredCubeMap8 = cubemap.resources[5];
    star.model.meshInstances[0].material._prefilteredCubeMap4 = cubemap.resources[6];
    star.model.meshInstances[0].material.metalness = 0;
    star.model.meshInstances[0].material.update();
    star.tags.add('refract');

    return star;
  };

  const createText = async () => {
    const modelAsset = await new Promise((resolve, reject) => {
      pc.app.assets.loadFromUrl('/models/text/text-model.json', 'model', (error, asset) => {
        error ? reject(error) : resolve(asset);
      });
    });

    const text = new pc.Entity('text');
    text.addComponent('model', {
      asset: modelAsset,
    });
    return text;
  };

  const createLight = () => {
    const light = new pc.Entity('light');
    light.addComponent('light', {
      type: 'point',
      //color: new pc.Color(245/255, 1, 4/255),
      intensity: 0.5,
    });

    light.setEulerAngles(-90, 0, 0);
    light.light.layers.push(5);

    return light;
  };

  const createAmbientList = () => {
    const ambientLight = new pc.Entity('light');
    ambientLight.addComponent('light', {
      intensity: 0.5,
    });
    ambientLight.setEulerAngles(0, -135, 0);

    ambientLight.light.layers.push(5);
    return ambientLight;
  };

  const createCamera = async () => {
    const refractionShader = await new Promise((resolve, reject) => {
      pc.app.assets.loadFromUrl('/models/chunk-refraction-fs.glsl', 'shader', (error, asset) => {
        error ? reject(error) : resolve(asset);
      });
    });

    const camera = new pc.Entity('camera');
    camera.addComponent('camera', {
      clearColor: new pc.Color(26 / 255, 26 / 255, 26 / 255),
      horizontalFov: true,
      fov: 37,
    });

    console.log(camera.camera.fov)

    camera.setPosition(0, 1, 0);
    camera.setEulerAngles(-90, 0, 0);
    camera.addComponent('script');
    camera.script.create('refraction', {
      attributes: {
        chunkRefractFS: refractionShader,
      },
    });
    camera.camera.layers.push(5);

    return camera;
  };

  const initPlayCanvas = useCallback(async () => {
    // App and settings
    const app = new pc.Application(canvasRef.current, {});

    app.keyboard = new pc.Keyboard(window);
    app.mouse = new pc.Mouse(canvasRef.current);
    app.setCanvasFillMode(pc.FILLMODE_FILL_WINDOW);
    app.setCanvasResolution(pc.RESOLUTION_AUTO);
    generateRefractionScript(pc);

    // create layers
    const refractionLayer = new pc.Layer({
      name: 'Refraction',
      opaqueSortMode: 2,
      transparentSortMode: 3,
    });
    app.scene.layers.insert(refractionLayer, 4);

    const camera = await createCamera();
    const light = createLight();
    const ambientLight = createAmbientList();
    const star = await createStar();
    const text = await createText();

    const onResize = () => {
      app.resizeCanvas();
      const height = app.graphicsDevice.height;
      const width = app.graphicsDevice.width;
      camera.camera.horizontalFov = height > width;
      camera.camera.fov = height > width ? 45 : 37;
    };

    window.addEventListener('resize', onResize);
    onResize();

    starTargetPositionRef.current = starDefaultPositionRef.current.clone();

    app.root.addChild(ambientLight);
    app.root.addChild(camera);
    app.root.addChild(text);
    app.root.addChild(star);

    // Events
    app.mouse.on(pc.EVENT_MOUSEMOVE, onMouseMove);
    appRef.current = app;
    mouseTestBoundingBoxRef.current.halfExtents = new pc.Vec3(0.5, 0.1, 0.3);

    app.on('update', function (dt) {
      const newStarPosition = new pc.Vec3();

      newStarPosition.lerp(star.getPosition(), starTargetPositionRef.current, 15 * dt);
      star.setPosition(newStarPosition);

      const newTextEuler = new pc.Vec3();
      newTextEuler.lerp(text.getEulerAngles(), textTargetEulerRef.current, 15 * dt);

      text.setEulerAngles(newTextEuler);
      star.setEulerAngles(newTextEuler);

      newStarPosition.y = -1;
      light.setPosition(newStarPosition);
    });
    app.start();

    return () => {
      window.removeEventListener('resize', onResize);
      app.mouse.off(pc.EVENT_MOUSEMOVE, onMouseMove);
      app.destroy();
    };
  }, []);

  useEffect(() => {
    let destroy;
    initPlayCanvas().then((cleaner) => (destroy = cleaner));
    return () => {
      destroy();
    };
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} className={cx({canvas: true, hidden: location.pathname !== '/'})} />
    </div>
  );
};

export default Playcanvas;
