import {Route, Switch, useLocation} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import React from 'react';
import styles from './styles.module.scss';
import About from '../about/About';
import TellMe from '../tell-me/TellMe';
import Portfolio from '../portfolio/Portfolio';
import Case from "../case/Case";

const Page = ({children}) => {
  return <div className={styles.page}>{children}</div>;
};

const AppRouter = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <Route path="/" exact>
            <Page />
          </Route>
          <Route path="/about">
            <Page>
              <About />
            </Page>
          </Route>
          <Route path="/portfolio" exact>
            <Page>
              <Portfolio />
            </Page>
          </Route>
          <Route path="/portfolio/:id">
            <Page><Case /></Page>
          </Route>
          <Route path="/tell-me">
            <Page>
              <TellMe />
            </Page>
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AppRouter;
