import React, {useState} from 'react';
import styles from './styles.module.scss';
import Case from './case/Case';
import classnames from 'classnames/bind';
import {shallowEqual, useSelector} from "react-redux";
import Masonry from 'react-masonry-css'

const cx = classnames.bind(styles);

const Portfolio = () => {
    const [category, setCategory] = useState('projects');
    const {portfolio} = useSelector(store => store.app, shallowEqual);

    const categories = ['projects', 'posters'];

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className={styles.portfolio}>

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className={styles.grid}
                columnClassName={styles.column}>
                {portfolio
                    .filter((c) => categories.includes(c.category))
                    .map((item) => (
                        <Case item={item} key={item.id}/>
                    ))}
            </Masonry>

            <div className={cx({grid: true, [category]: true})}>
                {/*<div className={styles.categories}>
          {categories.map((c) => (
            <div key={c} onClick={() => setCategory(c)} className={cx({category: true, active: c === category})}>
              {c}
            </div>
          ))}
        </div>*/}


            </div>
        </div>
    );
};

export default Portfolio;
