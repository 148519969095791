import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import app from './app';

const reducers = combineReducers({
  app,
});

const initialState = {};

const store = createStore(reducers, initialState, applyMiddleware(thunk));

export default store;
