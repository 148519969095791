/*
Refraction script, adds realtime refraction rendering capabilities.

Setup scene:
1. Add "Refraction" layer in Editor.
2. Add Refraction opaque and transparent rendering order, after World and before Immediate layers in Editor.
3. On entities that have to refract, set material that have "Refraction" value more than 0 and a CubeMap applied, either from scene skybox or individually on material.
4. Do not use same material on non refractive models.
5. Add tags "refract" to all entities that have to refract.

Limitations:
1. Glossiness - for that pre-bluring of screen texture required with interpolation in shader.
2. Overlapping refraction objects, only closest to camera will refract ignoring refraction pixels behind from other objects.
3. Skybox will not be rendered.
*/


const generateScript = (pc) => {
    var Refraction = pc.createScript('refraction');

    Refraction.attributes.add('chunkRefractFS', {
        type: 'asset',
        assetType: 'shader',
        title: 'Shader Fragment'
    });

    Refraction.attributes.add('downscale', {
        type: 'number',
        title: 'Downscale',
        min: 0.001,
        max: 1,
        default: 0.5,
        description: 'Multiplayer of resolution, lower will bring quality down, but speedup rendering'
    });

    Refraction.prototype.initialize = function() {
        this.firstRender = true;
        this.renderTarget = null;

        var layers = this.app.scene.layers;

        this.layerWorld = layers.getLayerByName('World');
        this.layerSkybox = layers.getLayerByName('Skybox');
        this.layerRefraction = layers.getLayerByName('Refraction');

        this.on('enable', function() {
            this.layerWorld.renderTarget = this.renderTarget;
            this.layerSkybox.renderTarget = this.renderTarget;
            this.layerRefraction.enabled = true;

            this.resize();
        });
        this.on('disable', function() {
            this.layerWorld.renderTarget = undefined;
            this.layerSkybox.renderTarget = undefined;
            this.layerRefraction.enabled = false;
        });

        this.app.graphicsDevice.on('resizecanvas', this.resize, this);
        this.on('attr:downscale', this.resize);


    };

// handle resizing
    Refraction.prototype.resize = function() {
        if (! this.enabled)
            return;

        var device = this.app.graphicsDevice;

        var width = Math.floor(device.width * this.downscale);
        var height = Math.floor(device.height * this.downscale);
        if (this.renderTarget && this.renderTarget.width === width && this.renderTarget.height === height)
            return;

        if (this.renderTarget)
            this.renderTarget.destroy();

        this.renderTarget = new pc.RenderTarget({
            colorBuffer: new pc.Texture(device, {
                width: width,
                height: height,
                format: pc.PIXELFORMAT_R8_G8_B8,
                addressU: pc.ADDRESS_CLAMP_TO_EDGE,
                addressV: pc.ADDRESS_CLAMP_TO_EDGE,
                mipmaps: false
            }),
            depth: true
        });

        this.layerWorld.renderTarget = this.renderTarget;
        this.layerSkybox.renderTarget = this.renderTarget;

        // set screenspace texture
        device.scope.resolve('texture_screen').setValue(this.renderTarget.colorBuffer);

        console.log('resized');

    };


    Refraction.prototype.postUpdate = function(dt) {
        if (this.firstRender) {
            this.firstRender = false;

            var self = this;
            var device = this.app.graphicsDevice;

            if (! this.layerRefraction) {
                console.log('Refraction: no "Refraction" layer found.');
                return;
            }

            // create render target
            this.resize();

            // for each entity marked as refract
            var entities = this.app.root.findByTag('refract');
            if (entities.length === 0)
                console.log('Refraction: no entities found. Mark entities with `refract` tags.');

            for(var i = 0; i < entities.length; i++) {
                var model = entities[i].model;
                if (! model) {
                    console.log('Refraction: entity "' + entities[i].name + '" have no model component.');
                    continue;
                }

                var modelLayers = model.layers.slice(0);
                if (modelLayers.indexOf(this.layerWorld.id) === -1) {
                    console.log('Refraction: entity "' + entities[i].name + '" is not on "World" layer.');
                    continue;
                }

                // remove world layer
                modelLayers.splice(modelLayers.indexOf(this.layerWorld.id), 1);
                // add to refraction layer
                if (model.layers.indexOf(this.layerRefraction.id) === -1)
                    modelLayers.push(this.layerRefraction.id);
                // update model layers
                model.layers = modelLayers;

                // update materials
                var meshes = model.meshInstances;
                for(var m = 0; m < meshes.length; m++) {
                    meshes[m].material.chunks.refractionPS = this.chunkRefractFS.resource;
                    meshes[m].material.update();
                }
            }

            // render again
            this.layerRefraction.addMeshInstances(this.layerWorld.opaqueMeshInstances);
            this.layerRefraction.addMeshInstances(this.layerWorld.transparentMeshInstances);

            // set screenspace texture
            device.scope.resolve('texture_screen').setValue(this.renderTarget.colorBuffer);
        }
    };

}

export default generateScript;
