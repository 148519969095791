import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './styles.module.scss';

const Header = () => {
  return (
    <div className={styles.header}>
      <NavLink to={'/'} exact className={styles.link} activeClassName={styles.activeLink}>
        index
      </NavLink>
      <NavLink to={'/about'} className={styles.link} activeClassName={styles.activeLink}>
        about
      </NavLink>
      <NavLink to={'/portfolio'} className={styles.link} activeClassName={styles.activeLink}>
        portfolio
      </NavLink>
      <NavLink to={'/tell-me'} className={styles.link} activeClassName={styles.activeLink}>
        tell me
      </NavLink>
    </div>
  );
};

export default Header;
