import React from 'react';
import styles from './styles.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      {year}
      <br />
      lisa
      <br />
      kuntysh
      <br />
    </div>
  );
};

export default Footer;
