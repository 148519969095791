import React from 'react';
import styles from './styles.module.scss'

const TellMe = () => {

    const email = 'lisakuntysh@gmail.com';

    return (<div className={styles.tellMe}>
        <div className={styles.text}>
            if you would like to reach out to discuss a potential project
            or collaboration ideas please write to <a href={'mailto:'+email}>{email}</a>
        </div>
    </div>)
}

export default TellMe;
