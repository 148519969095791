import React from 'react';
import {Link} from 'react-router-dom';
import styles from './styles.module.scss';
import {host} from '../../../services/api';

const Case = ({item}) => {
  const {photo, name, contact, id, category} = item;

  return <Link to={`/portfolio/${id}`} className={styles.case}>
      <img src={host + photo} alt={name} className={styles.image}/>
  </Link>

  /*if (category !== 'projects') {
    return <img src={host + photo} alt={name} />;
  }*/

  return (
    <Link to={`/portfolio/${id}`} className={styles.case}>
      <img src={host + photo} alt={name} className={styles.image}/>

     {/* <div className={styles.content}>
        {name} <br />
        <br />
        {contact}
      </div>*/}
    </Link>
  );
};

export default Case;
